@tailwind base;
@tailwind components;
@tailwind utilities;

.annonceImage {
  background-image: url('./assets/images/annonceImage.jpeg');
}

.scrollbar-hidden {
  overflow-x: auto;
  overflow-y: hidden;
}

/* Pour les navigateurs WebKit (Chrome, Safari) */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.active_button:focus {
  background: #9f9f9f;
  color: rgb(0, 0, 0);
}

.myPaymentUtils {
  z-index: 50;
  background-color: #00000052;
}

.bg-gray-900\/50 {
  z-index: -2 !important;
  background-color: rgba(23, 23, 23, 0);
}

.swal2-confirm {
  background-color: #7066e0;
}