.footer_new_letters {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 3.62rem;
  padding-bottom: 4.31rem;
  margin: 0 auto 0 auto;
  background-color: #d7e6f5;
}

.footer_safe_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #4d6bc6;
  color: var(--white);
  text-align: center;
  font-family: "Helvetica Neue";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
  padding: 1.91rem 0;
}

.footer_links {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: var(--sapphire);
  margin: 0 auto 0 auto;

  > div {
    display: flex;
    width: 100%;
    max-width: var(--max-width);
    padding: var(--horizontal-padding);
  }
  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6.88rem 8.69rem;
    background-color: #122868;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    flex: auto;
    padding: 2.5rem;
    &__group {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      &__title {
        color: var(--white, #fff);
        font-family: "Helvetica Neue";
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 125% */
      }

      &__links {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        color: #fff;
        font-family: "Helvetica Neue";
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1375rem; /* 140% */

        &__email {
          display: flex;
          align-items: center;
          gap: 0.94rem;
        }

        &__reseaux {
          display: flex;
          align-items: center;
          gap: 1.17rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .footer_links {
    > div {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 426px) {
  .footer_new_letters {
    overflow: hidden;
  }
  .footer_safe_banner {
    overflow: hidden;
  }
  .footer_links {
    > div {
      padding: 0;
      flex-direction: column;
    }
    &__right {
      gap: 1.25rem;
      overflow-x: scroll;
      padding: 0.5rem;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
  .footer_bottom {
    &__right {
      overflow: hidden;
      padding: 0.1rem;
    }
  }
}

.footer_bottom {
  display: flex;
  justify-content: center;
  padding: 1.12rem 0;
  background: #1a2640;
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: calc(var(--max-width) - 2rem);
    padding: var(--horizontal-padding);
    @media screen and (max-width: 426px) {
      padding: 0rem;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    > div {
      color: var(--white, #fff);
      text-align: center;
      font-family: "Helvetica Neue";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1375rem; /* 140% */
    }
  }

  &__right {
    display: flex;
    gap: 1.33rem;
    &__link {
      color: var(--white, #fff);
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem; /* 138.462% */
    }
    a {
      text-decoration: underline;
    }
  }
}
