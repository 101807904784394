/* Ajoute ceci dans ton fichier CSS global ou dans un fichier CSS module */
.recherche-container {
          position: relative;
          display: flex;
          justify-content: center;
          margin-top: 2rem;
          /* Ajuste l'espacement selon tes besoins */
}

.recherche-form {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 600px;
          /* Largeur maximale du champ de recherche */
          border-radius: 30px;
          /* Bords arrondis */
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          /* Ombre douce */
          overflow: hidden;
          /* Pour un bon arrondi */
}

.recherche-input {
          flex-grow: 1;
          padding: 10px 20px;
          /* Ajuste le padding pour un look plus aéré */
          border: none;
          /* Supprime la bordure par défaut */
          border-radius: 30px 0 0 30px;
          /* Arrondis à gauche */
          font-size: 16px;
          /* Taille de police */
          transition: all 0.3s;
          /* Transition douce */
}

.recherche-input:focus {
          outline: none;
          /* Supprime le contour par défaut */
          box-shadow: 0 0 5px rgba(66, 153, 225, 0.5);
          /* Ombre lors de la mise au point */
}

.recherche-button {
          padding: 10px 20px;
          border: none;
          background-color: #3140af;
          /* Couleur de fond */
          color: white;
          /* Couleur du texte */
          font-weight: bold;
          /* Texte en gras */
          border-radius: 0 30px 30px 0;
          /* Arrondis à droite */
          cursor: pointer;
          /* Curseur pointer */
          transition: background-color 0.3s, transform 0.3s;
          /* Transition douce */
}

.recherche-button:hover {
          background-color: #357ABD;
          /* Couleur au survol */
}